import './styles.scss';
import React,{useEffect, useState, Component,useRef} from 'react';
import {useParams, useSearchParams, useLocation} from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from 'axios';
import Data from '../../../../data/rss_new.json'
import HpaAds from '../../../../data/ads_hpa.json'
import AdData from '../../../../data/adsvid.json'
import Icon from '../Pictures/logo.svg'
import Mute from '../Pictures/mute4.svg'
import Alternative from '../Pictures/your_ad.png'
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import Noimage from '../Pictures/noimage.png'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
import CarouselAds from '../../../../data/carousel_ads_inpage.json'
import { register } from 'swiper/element/bundle';
import { Margin } from '@mui/icons-material';





register();
function BodyInst(){
  

   
   
     //let datas=Data.rss.channel.item
     let mute=true
     let urlParam=document.location.search;
    

     const [searchParams] = useSearchParams();
     const feedAd = searchParams.get('v');
     const feedAd2 = searchParams.get('v2');
     const hpa = searchParams.get('hpa');
   
     let interval

     let adlist=[]
    
     const refCarousel = useRef(null);
     const refCarousel2 = useRef(null);
     //const[videoplay, setVideoPlay]=useState(true)
     let videoVolume=false

     const[activeSlide, setActiveSlide]=useState(0)
     const[SlideCount, setSlideCount]=useState(0)

     const[activeSlide2, setActiveSlide2]=useState(0)
     const[SlideCount2, setSlideCount2]=useState(0)

     
     let animationPlayed=true
     let animationPlayed2=true

    
    const adVisibilityTimeCarousel1=useRef(0)
    const visibilityIntervalCarousel1=useRef(null)

    const adHpaVisibilityTime=useRef(0)
    const visibilityIntervalHpa=useRef(null)
    const HpaAd=useRef(null)
    let checkVar=0
     
       
     function clearInterfal(){
          clearInterval(interval)
     }


     const handleChangeVolume=(e)=>{
          videoVolume=!videoVolume
          console.log(videoVolume)
          if(videoVolume){
              
               e.target.muted=false
              e.target.volume=1
              document.getElementById("feedad2").style.opacity=0
              
          }
          else{
             
               
               e.target.volume=0
               document.getElementById("feedad2").style.opacity=1
          }
     }


 
    async function AnimationStart(elem1, elem2, elem3, classname){
             
                    if(classname[1]!="fewoclassStatic"){
             
                    setTimeout(() => {
                         elem1.style.opacity=1
                         setTimeout(() => {
                              elem1.style.opacity=0
                              elem2.style.opacity=1
                             setTimeout(() => {
                               elem3.style.opacity=1
                              
                               //elem3.classList.add('feed-carousel-slide-AdCTAActive')
                             }, 2000);
                         }, 3000);
                    }, 1000);
           
               }
     }


     
     const handleOpenTextField=(e)=>{

          const parentDiv = e.target.closest('.post-text_container');
            const paragraph = parentDiv.querySelector('p'); 
          paragraph.style.webkitLineClamp='unset'
        
          e.target.style.display="none"
        
        }
     
    
     

     useEffect(() => {

          
          window.addEventListener('scroll', handleCalcScroll)

          const swiperEl = document.querySelector('swiper-container');
         
          let target=refCarousel.current
          let target2=refCarousel2.current

        
       
          setSlideCount(swiperEl.swiper.slides.length)
          setActiveSlide(swiperEl.swiper.activeIndex+1)
        

     

          swiperEl.swiper.on('slideChange',()=>{
            
               setActiveSlide(swiperEl.swiper.activeIndex+1)
           
                target=refCarousel.current
              
                
               
              
               
          })



          const callback =(entries, observer)=>{
               entries.forEach(entry=>{
                    const {target, isIntersecting}=entry
                    if(isIntersecting){
                     
                      if(visibilityIntervalCarousel1.current){
                        clearInterval(visibilityIntervalCarousel1.current)
                      }
                     
                
                      visibilityIntervalCarousel1.current=setInterval(()=>{
                         adVisibilityTimeCarousel1.current++
                       // console.log(adVisibilityTime.current)
            
                        //eslint-disable-next-line
                        sendResultFieldData({setResultFields: true, 1:String("visibility "  + CarouselAds.items[feedAd][0].item._attributes.data + " " + adVisibilityTimeCarousel1.current)})
            
                      },1000)
                    }
                
                    else{
                 
                      clearInterval(visibilityIntervalCarousel1.current)
                      
                      //eslint-disable-next-line
                      sendResultFieldData({setResultFields: true, 1:String("visibility "  + CarouselAds.items[feedAd][0].item._attributes.data + " " + adVisibilityTimeCarousel1.current)})
                      
                      
                
                      
                       
                      
                      
                    }
                
                
                  })
         }
        
     
         const options={
          threshold: 0.4,
         
          
          
        }
     
      
     
          const observer=new IntersectionObserver(callback, options)
          observer.observe(target)
         
     
          //HPA Observer
         
        
         
    //CarouselAds.items[feedAd][0].item._attributes.logostyle
          

          swiperEl.swiper.on('slideChange',()=>{
            //eslint-disable-next-line
            sendResultFieldData({setResultFields: true, 2:String("swipe: " + CarouselAds.items[feedAd][0].item._attributes.data)})
            console.log("swipe")
            
          })

      


          
         }, []);


         const handleCalcScroll=()=>{
  
          let fullSize=(document.documentElement.scrollHeight-window.scrollY)-document.documentElement.clientHeight+window.scrollY
          let percentResult=Math.round((window.scrollY*100)/fullSize)
          
          if(percentResult>=checkVar){
            checkVar=percentResult
           
            //eslint-disable-next-line
            sendResultFieldData({setResultFields: true, 4:String("Scroll " + percentResult)})
          }
         
            //console.log("percent<check " + " check: " + checkVar + " percent: " + percentResult)
        
        
        }

      //data-click-tracking="reweka_long_mitBtn_optionen"

        return Data.posts.map((item,index)=>{
       

          if(index==2){
               return(
                    <div className='Post_Carousel'  key={index}>
               
                    <div className='carousel-Icon_Name' id="icon_name">
                      
                         <div className='minPic-container'>
                        <img className='MinPic' id={CarouselAds.items[feedAd][0].item._attributes.logostyle} src={CarouselAds.items[feedAd][0].item._attributes.logo}/>
                        </div>
                    
                    <div className='carousel-Icon_Name_container'>
                    <div className='carousel-Icon_Name-Name'>{CarouselAds.items[feedAd][0].item._attributes.accname}</div>
                    <p className='carousel-Icon_Name-ad_label'>Anzeige</p>
                    </div>
                    </div>
    
                    <div ref={refCarousel} style={{position:'relative', marginTop:'10px'}}>
                   {/*<div className='incheck-carousel-count'>{swiperEl.swiper.activeIndex+"/"+swiperEl.swiper.slides.length}</div>*/} 
                   <div className='incheck-carousel-count'>{activeSlide+"/"+CarouselAds.items[feedAd][0].item._attributes.amount}</div>
                   
                    <div className='incheck-carousel_container'>
                    <swiper-container id="incheck-carousel" pagination="true" class="mySwiper" style={{height:'100%', position:'relative'}} slides-per-view="1">
                   
                  <Sliders/>
                  
                
                    </swiper-container>

                    <div className='ad_klicks feed-carousel-slide-AdCTA-container' data-click-tracking={CarouselAds.items[feedAd][0].item._attributes.data}>
                    <div className='feed-carousel-slide-AdCTA'><p style={{marginLeft:'10px'}}>{CarouselAds.items[feedAd][0].item._attributes.cta} </p></div>
                    <div className='feed-carousel-slide-linkBtn'>{`>`}</div>
                    </div>
                    </div>
                   
                    
                    </div>

                    <div className='carousel-buttons-foot' style={{paddingBottom:'30px'}}>
                     <FavoriteBorderOutlinedIcon style={{paddingLeft: '3%'}}/>
                     <ModeCommentOutlinedIcon style={{paddingLeft: '5%'}}/>
                     <SendOutlinedIcon style={{paddingLeft: '5%', transform:'rotate(333deg)'}}/>
                     
        
                     <BookmarkBorderOutlinedIcon style={{float:'right'}}/>
    
                     </div>

                     <div  className='DivNone'>
        
                     <p className='PostText'> <span style={{fontWeight:'bold'}}>{item.username}</span> {item.caption}</p>
                    
                    
                     <p style={{lineHeight:1, fontSize:'small', color:'#7F7F7F', marginLeft:'3%'}}>Kommentare ansehen</p>
    
                     </div>
        
                    </div>
                    
               )
          }
          
          else{
  
        return(

           
                <div className='Post'  key={index}>
               
                <div className='Icon_Name' id="icon_name">
                  
                <div className='minPic-container'>
                    <img className='MinPic' src={Icon}/>
                 </div>
                 
                
                <div className='carousel-Icon_Name-Name'>{index==2 ? 'Ad' : item.username}</div>
                </div>

                <div style={{position:'relative'}}>
                <img id={index} className='Pic' src={item.display_src} onError={(e)=>{e.target.onerror = null; e.target.src=Noimage}}/>
                <Box  id={`loader`+index} className={index==2 ? 'loaderIcon' : 'loaderIconNone'}>
                   <CircularProgress />
                </Box>
                <div id={`feedad`+index} className={index==2 ? 'muteIcon' : 'muteIconNone'}>
                <img  src={Mute} style={{width:'15px', marginTop:'2px'}}/>
                </div>

                
                </div>
                 <FavoriteBorderOutlinedIcon style={{paddingLeft: '3%'}}/>
                 <ModeCommentOutlinedIcon style={{paddingLeft: '5%'}}/>
                 <SendOutlinedIcon style={{paddingLeft: '5%', transform:'rotate(333deg)'}}/>
                 
    
                 <BookmarkBorderOutlinedIcon style={{float:'right'}}/>

                 <div className="Div">

                  <div className='post-text_container'>
                 <p className='PostText'> <span style={{fontWeight:'bold'}}>{item.username}</span> {item.caption}</p>
                 <div onClick={handleOpenTextField} className='post-text_more'>...mehr</div>
                 </div>
                
                 <p style={{lineHeight:1, fontSize:'small', color:'#7F7F7F', marginLeft:'3%'}}>Kommentare ansehen</p>

                 <p style={{lineHeight:1, fontSize:'small', color:'#7F7F7F', marginLeft:'3%'}}>{item.post_date}</p> 

                 </div>
    
                </div>
                
                
            
    
        );
     }

     })
       
     function Sliders(){
          //CarouselAds.items[feedAd2][0].item._attributes.img
          return CarouselAds.items[feedAd].map((item,index)=>{
               if(feedAd==2){ // FEWO
                    return(
                         <swiper-slide key={index}>
                         <div className='feed-carousel-slide'>
          
                         <img className='feed-carousel-slide-item' src={CarouselAds.items[feedAd][index].item._attributes.img}/>
                         
                         </div>
                    </swiper-slide>
                    )
               }
               else{
               return(
                    <swiper-slide key={index}>
                    <div className='feed-carousel-slide'>
                    <img className='feed-carousel-slide-item' src={CarouselAds.items[feedAd][index].item._attributes.img}/>
                    
                    </div>
                    </swiper-slide>
               )
          }
          })
     }
 
     
  
}

export default BodyInst
